
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import {
  IRastreabilidade,
  IRastreabilidadeOrigem,
} from '@/models/Entidades/Estoques/IRastreabilidade';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoRastreabilidade';
import SelecionarTipoRastreabilidade from '@/components/Estoques/Rastreabilidade/SelecionarTipoRastreabilidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ServicoRastreabilidade from '@/servicos/Estoques/ServicoRastreabilidade';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import SelecionarTipoLancamentoRastreabilidade from './SelecionarTipoLancamentoRastreabilidade.vue';
import { IDTORastreabilidade } from '@/models/DTO/Estoques/Rastreabilidade/IDTORastreabilidade';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IParametroConsultaRastreabilidade } from '@/models/ParametrosRequisicao/Estoques/IParametroConsultaRastreabilidade';

export default defineComponent({
  name: 'SelecaoRastreabilidadeModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    codigoDefinicaoProduto: {
      type: Number,
      required: true,
    },
    itemEstoque: {
      type: Number,
      default: 0,
    },
    quantidade: {
      type: Number,
      default: 0,
    },
    itemVenda: {
      type: Number,
      required: true,
    },
    tipoLancamento: {
      type: Number,
      default: 1,
    },
    lancouRastreabilidades: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarTipoRastreabilidade,
    CampoNumerico,
    SelecionarTipoLancamentoRastreabilidade,
    MensagemSemDados,
  },
  emits: ['update:visivel', 'salvar', 'cancelar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase, apresentarBarraProgresso, ocultarBarraProgresso } = useModalBase(props, emit);
    const { gradeBase, carregarPreferenciasGrade } = useGradeBase();
    const servicoRastreabilidade = new ServicoRastreabilidade();
    const servicoProduto = new ServicoProduto();
    servicoRastreabilidade.requisicaoSistema();
    servicoProduto.requisicaoSistema();

    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasMobile = [
      {
        title: 'Rastreabilidade', dataIndex: '', key: 'Rastreabilidade', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      definicaoProduto: {} as IDTOProdutoDefinicao,
      rastreabilidades: [] as IDTORastreabilidade[],
      rastreabilidadesSelecionadas: [] as IRastreabilidade[],
      identificadorSelecionado: '',
      qtdSelecionada: 0,
      qtdTotalSelecionada: 0,
      rastreabilidadeSelecionada: -1,
      buscaRapida: '',
      lancouRastreabilidades: false,
    });

    function preencherColunas() {
      switch (state.definicaoProduto.tipoRastreabilidade) {
        case ETipoRastreabilidade.Outros:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 100,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Disponível', dataIndex: 'quantidade', key: 'quantidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Qtd Selecionada', dataIndex: 'qtdSelecionada', key: 'qtdSelecionada', position: 4, visible: true, ordering: true, align: 'right', width: 100, customRenderRow: ECustomRenderRow.MascararFracionado,
            },
          ];
          break;
        case ETipoRastreabilidade.Lote:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 140,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Disponível', dataIndex: 'quantidade', key: 'quantidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Qtd Selecionada', dataIndex: 'qtdSelecionada', key: 'qtdSelecionada', position: 4, visible: true, ordering: true, align: 'right', width: 100, customRenderRow: ECustomRenderRow.MascararFracionado,
            },
            {
              title: 'Nº do Lote', dataIndex: 'numeroLote', key: 'NumeroLote', position: 5, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: true, align: 'center', width: 100,
            },
          ];
          break;
        case ETipoRastreabilidade.Armamento:
          gradeBase.colunasPadrao = [
            {
              title: 'Codigo', dataIndex: 'codigo', key: 'codigo', position: 0, visible: false,
            },
            {
              title: 'Identificador', dataIndex: 'identificador', key: 'Identificador', position: 1, visible: true, ordering: true, align: 'left', width: 140,
            },
            {
              title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, align: 'left',
            },
            {
              title: 'Qtd Rastreabilidades', dataIndex: 'quantidade', key: 'quantidade', position: 3, visible: true, ordering: true, align: 'right', width: 140,
            },
            {
              title: 'Qtd Selecionada', dataIndex: 'qtdSelecionada', key: 'qtdSelecionada', position: 4, visible: true, ordering: true, align: 'right', width: 100, customRenderRow: ECustomRenderRow.MascararFracionado,
            },
            {
              title: 'Nº Arma', dataIndex: 'numeroSerieArma', key: 'NumeroSerieArma', position: 5, visible: true, ordering: true, align: 'center', width: 100,
            },
            {
              title: 'Nº Cano', dataIndex: 'numeroSerieCano', key: 'NumeroSerieCano', position: 6, visible: true, ordering: true, align: 'center', width: 100,
            },
          ];
          break;
        default:
          gradeBase.colunasPadrao = [];
          break;
      }
    }

    async function limparTela() {
      state.definicaoProduto = {} as IDTOProdutoDefinicao;
      state.rastreabilidades = [] as IDTORastreabilidade[];
      state.rastreabilidadeSelecionada = -1;
      state.identificadorSelecionado = '';
      state.qtdTotalSelecionada = 0;
      state.qtdSelecionada = 0;
      state.buscaRapida = '';
      state.lancouRastreabilidades = false;
    }

    function filtrar() {
      state.rastreabilidades.forEach((r, i) => {
        if (state.buscaRapida === '') {
          state.rastreabilidades[i].visivel = true;
        } else if (state.buscaRapida.toUpperCase().includes(r.identificador.toUpperCase()) || state.buscaRapida.toUpperCase().includes(r.descricao.toUpperCase()) || state.buscaRapida.toUpperCase().includes(r.numeroLote.toUpperCase()) || state.buscaRapida === UtilitarioData.aplicaFormatoData(r.dataFabricacao)
            || state.buscaRapida === UtilitarioData.aplicaFormatoData(r.dataVencimento) || state.buscaRapida.toUpperCase().includes(r.numeroSerieArma.toUpperCase()) || state.buscaRapida.toUpperCase().includes(r.numeroSerieCano.toUpperCase())) {
          state.rastreabilidades[i].visivel = true;
        } else {
          state.rastreabilidades[i].visivel = false;
        }
      });
    }

    function validarCampos() {
      if (state.identificadorSelecionado === '' || state.identificadorSelecionado === undefined) {
        apresentarMensagemAlerta('O identificador deve ser informado!');
        return false;
      }
      const indexRast = state.rastreabilidades.findIndex((r) => r.identificador === state.identificadorSelecionado);
      if (indexRast === -1) {
        apresentarMensagemAlerta('Nenhuma rastreabilidade com o identificador informado!');
        return false;
      }

      if (state.rastreabilidades[indexRast].quantidade < state.qtdSelecionada) {
        apresentarMensagemAlerta('A quantidade selecionada não pode ser maior que a quantidade disponível da rastreabilidade');
        return false;
      }

      const qtd = state.qtdSelecionada + state.qtdTotalSelecionada;
      if (qtd > props.quantidade) {
        apresentarMensagemAlerta('A quantidade adicionada não pode ser maior que a quantidade do item da venda');
        return false;
      }
      return true;
    }

    function adicionar() {
      if (!validarCampos()) return;
      const indexRast = state.rastreabilidades.findIndex((r) => r.identificador === state.identificadorSelecionado);
      if (state.rastreabilidadeSelecionada === -1) {
        state.rastreabilidades[indexRast].qtdSelecionada += state.qtdSelecionada;
      } else {
        state.rastreabilidades[indexRast].qtdSelecionada = state.qtdSelecionada;
      }

      state.qtdSelecionada = 0;
      state.identificadorSelecionado = '';
      state.rastreabilidadeSelecionada = -1;

      state.qtdTotalSelecionada = 0;
      state.rastreabilidades.forEach((r) => {
        state.qtdTotalSelecionada += r.qtdSelecionada;
      });
    }

    function editar(index: number) {
      state.identificadorSelecionado = state.rastreabilidades[index].identificador;
      state.qtdSelecionada = state.rastreabilidades[index].qtdSelecionada;
      state.rastreabilidadeSelecionada = index;
    }

    async function confirmar() {
      if ((props.quantidade - state.qtdTotalSelecionada) !== 0) {
        apresentarMensagemAlerta('A quantidde total das rastreabilidade não é igual a quantidade do produto');
        return;
      }

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retorno = await servicoRastreabilidade.excluirOrigem(props.itemVenda, 'PAINEL_VENDAS');
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemAlerta(retorno.mensagem);
        return;
      }
      state.rastreabilidades.forEach((r) => {
        if (r.qtdSelecionada > 0) {
          const selecao = {} as IRastreabilidade;
          selecao.origem = {} as IRastreabilidadeOrigem;
          selecao.codigo = r.codigo;
          selecao.identificador = r.identificador;
          selecao.origem.quantidade = r.qtdSelecionada;
          state.rastreabilidadesSelecionadas.push(selecao);
        }
      });

      if (state.rastreabilidadesSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma rastreabilidade lançada!');
        return;
      }
      apresentarBarraProgresso();
      retorno = await servicoRastreabilidade.lancarRastreabilidadeSaida(props.itemVenda, 'PAINEL_VENDAS', state.rastreabilidadesSelecionadas);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.lancouRastreabilidades = true;
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      modalBase.computedVisivel = false;
    }

    async function obterDadosProduto() {
      state.definicaoProduto = await servicoProduto.obterDadosDetalhesDefinicao(props.codigoDefinicaoProduto);
      state.definicaoProduto.tipoRastreabilidade = ETipoRastreabilidade.Lote;
      preencherColunas();
      carregarPreferenciasGrade(telaBase.preferencias);
      const parametros = {} as IParametroConsultaRastreabilidade;
      parametros.codigoItemEstoque = props.itemEstoque;
      parametros.codigoMovimento = props.itemVenda;
      parametros.codigoProdutoDefinicao = props.codigoDefinicaoProduto;
      parametros.quantidadeProduto = props.quantidade;
      parametros.tipoLancamento = props.tipoLancamento;
      console.log('tipoLancamento: ', parametros.tipoLancamento, 'tipolancamentovenda: ', props.tipoLancamento);
      parametros.recurso = 'PAINEL_VENDAS';
      state.rastreabilidades = await servicoRastreabilidade.obterRastreabilidadesProduto(parametros);
      state.rastreabilidades.forEach((r) => {
        state.qtdTotalSelecionada += r.qtdSelecionada;
      });
    }

    function cancelar() {
      emit('cancelar');
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        obterDadosProduto();
        telaBase.carregando = false;
      }
    });

    watch(async () => props.lancouRastreabilidades, async () => {
      state.lancouRastreabilidades = props.lancouRastreabilidades;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      ETipoRastreabilidade,
      ECustomRenderRow,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      adicionar,
      editar,
      confirmar,
      filtrar,
      cancelar,
    };
  },
});
